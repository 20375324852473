// Override default variables before the import
$body-bg: #000;
// Import Bootstrap and its default variables
@import "~bootstrap/scss/bootstrap.scss";

body {
  color: #fff;
  background-color:#040A1B;
  padding:20px;
}

h1{
    margin-bottom: -5px;
    width: 100%;
    text-align: center;
}
.heading-desc {
    display: block;
  font-size: 12px;
  width: 100%;
  text-align: center;
}
.app-header{
    text-align: center;
}
.img-logo{
    max-width: 350px;
}
